import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layouts/default'

const PageGettingStarted = () => (
	<Layout>

		<div className="container-fluid ">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em', fontWeight: 200 }}>Getting Started</h1>
			<div className="container">

				<div className="row">
					<div className="col-md-3 mb-4  card card-feature" style={{minHeight: '100vh'}}>
						<div className="py-2 sticky-top">
							<h6 class="menu-title">Getting Started</h6>
							<div className="nav flex-column">
								<a href="#intro" className="nav-link activex">Introduction</a>
								<a href="#interface" className="nav-link">Interface overview</a>
								<a href="#add-contacts" className="nav-link">Adding Contacts</a>
								<a href="#find-contacts" className="nav-link">Finding Contacts</a>
								<a href="#add-organisations" className="nav-link">Adding Organisations</a>
								<a href="#assign-organisations" className="nav-link">Assigning Contacts to Organisations</a>
								<a href="#opportunities" className="nav-link">Creating Opportunities</a>
								<a href="#opportunities-filtering" className="nav-link">Filtering Opportunities</a>
								{/* <a href="#sales-pipeline" className="nav-link">Creating your sales pipeline</a> */}
								{/* <a href="#tasks" className="nav-link">Managing your Tasks</a> */}
								<a href="#website" className="nav-link">Integrating Reldesk into your website</a>
								{/* <a href="#next-steps" className="nav-link">Next steps</a> */}
							</div>
						</div>
					</div>
					<div className="col-md-9 mb-4">
						<div className="card card-feature">
							<div className="help-body" style={{padding: 40}}>
								<p className="pt-2" style={{fontWeight: 100, fontSize: 18}}>This Getting Started Guide is designed to be a light
								introduction to Reldesk to give you an overview of the basic capabilities. For more in-depth use and sales strategies please book a training session. {/* For more detailed documentation, please see the Documentation section. We'll point out
								key areas where you can find out more in the main documentation section where relevant. */}</p>
								<h2 id="intro">Introduction</h2>
								<p>
									The best way to get started with Reldesk is to begin creating contacts. A contact is the primary record within Reldesk and keeps the
									main details of one person together in one place.
								</p>

								<p>
									To follow along, ensure you have a registered account and are logged in. You should see the Welcome
									to Reldesk <strong>Dashboard</strong> page.
								</p>
		
								<h2 id="interface">Interface Overview</h2>
								<h3>Main Menu</h3>
								<p>
									At the top of the screen is the main menu which, depending on your package and user account, shows the main areas of functionality. Clicking on any of these will go to the relevant section, and clicking the Reldesk logo will direct you back to the Dashboard.
								</p>

								<img src="/menu-bar.png" className="mt-3 mb-4 doc-image img-fluid" alt=""/>
								<p>
									Over on the right-hand side you also have access to news and notifications to stay up to date, and the profile/account settings to manage your
									Reldesk account and 'Team'.
								</p>
								<img src="/profile-menu.png" className="mt-3 mb-4 doc-image" alt="" width="200"/>

								<h3>Dashboard</h3>
								<p>
									When you login or click on the Reldesk logo you'll be presented by the Dashboard which gives you a brief overview of some key areas of your Reldesk account.
								</p>
								<img src="/dashboard.png" className="mt-3 mb-4 doc-image img-fluid" alt=""/>

								

								<h2 id="add-contacts">Adding Contacts</h2>
								<p>
									One of the first things to do is to start adding some of your contacts. To begin, we'll enter a fictional contact to show the process and some of the special fields that make input easier.
								</p>
								<p>
									To get started simply select the <strong>Contacts</strong> item on the main menu bar at the top of the screen.
								</p>
								
								<img src="/add-contact.png" className="mt-3 mb-5 doc-image img-fluid" alt="" width="350"/>

								<p>
									Here you’ll see an existing list of contacts, if you have any, and an <strong>+ Add Contact</strong> Button on the right.
								</p>

								<p>
									Click this and up with popup a New Contact form. We'll just enter some details for firstname, lastname, email, position and the telephone numbers.
								</p>

								<img src="/contact-example1.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>


								<p>
								There are two ways to add a Company/Organisation, we'll opt for just entering in the contact's company name for now:
								</p>
								<img src="/company-name.png" className="mt-3 mb-5 doc-image img-fluid" alt="" width="500"/>

								<p>
								We'll then use the Address search feature to lookup our address. Just start typing the address, postcode or place name to find it. Once it pops up, just click the address to auto-populate all the fields (including it's location).
								</p>

        						<video src="/address-lookup.mp4" type="video/mp4s" className="mt-3 mb-5 doc-image img-fluid" autoplay controls/>

								<p>
								If you can't find the address, or want to edit any of the details, simple edit the fields manually.
								</p>
								
								<p>
									Once you're done, hit the <strong>Create</strong> button to save the new contact 
								</p>
								<img src="/create-button.png" className="mt-3 mb-5 doc-image img-fluid" alt="" width="200"/>

								<p>
									You can now see your new contact listed in the contacts section.
								</p>
								<img src="/contact-example-list.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>

								<p>
									<strong>Congratulations, you've created your first Reldesk contact!</strong>
								</p>


								<h2 id="find-contacts">Finding Contacts</h2>
								<p>
									To search and filter your contacts list you can use a variety of options straight from the main Contacts screen:
									<ul className="pt-3 pb-3">
										<li>Filter by Contact Type</li>
										<li>Filter by Text Search</li>
										<li>Filter by Tags</li>
										<li>Sorting Results</li>
									</ul>
								</p>

								<p>All of these options are listed on the filter bar shown below</p>
								<img src="/contact-filters.png" className="mt-3 mb-2 doc-image img-fluid" alt=""/>


								<h4>Filter by Contact Type</h4>
								<p>
									By selecting the Contact Type Dropdown on the left (Currently shown as 'All Contacts'), you can limit the contacts to
									those which are in that category. For example, selecting 'Lead' will show only Leads, and selecting 'Client' will only show Clients.
								</p>

								<h4>Filter by Text Search</h4>
								<p>
									To find results based on a text search you can simply start typing into the Search field to limit the results. It currently searches the contact name and company.
								</p>

								<h4>Filter by Tags</h4>
								<p>
									Tags are a great way to segment and filter contacts. To do this simple type a tag name in the Tag box and hit enter to filter by that tag.
									You can also add more tags to limit the results even further 
								</p>

								<h4>Sorting Results</h4>
								<p>
									Once you've added your filtering options (you can combine them by the way) you'll probably want to sort the results so just select 
									the preferred option from the Sort by field and choose either Ascending or Descending to set the order.
								</p>



								<h2 id="add-organisations">Adding Organisations</h2>
								<p>
									Although by no means required, some sales processes and management perform better when you can put multiple
									contacts under one umbrella - that's where organisations come in. Useful for companies and organisational strcutures.
								</p>

								<p>
									Just select the <strong>Organisations</strong> item from the main menu bar at the top of the screen. Once on the
									Organisations screen press the <strong>Add Organisation</strong> button on the right.
								</p>

								<img src="/orgs-list.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>


								<p>
									You'll now see the Add Organisation screen where you can begin putting in the details. Let's just enter the company name in the Organisation Name field.
								</p>

								<img src="/orgs-add.png" className="mt-3 mb-5 doc-image img-fluid" alt="" width="600"/>

								<p>
									Hit the <strong>Create</strong> button and you'll have a new Organisation entry. 
								</p>



								<h2 id="assign-organisations">Assigning Contacts to Organisations</h2>
								<p>
									If you have multiple people in companies or organisations you'll probably want to group them together under the Organisation entry. So once you've created your organisation, simple go to the contacts screen and select Edit contact from the dropdown menu on the right.
								</p>

								<img src="/contact-edit-menu.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>

								<p>
									In the Edit window you can simple select the Organisation from the dropdown and hit the Save button.
								</p>

								<img src="/contact-edit-org.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>


								<h2 id="opportunities">Creating Opportunities</h2>
								<p>
									To track your opportunities or sales pipeline you use the Opportunities screen to add and manage their details and progress. To access it, select the <strong>Opportunities</strong> item from the main menu and you'll see the list of current Opportunities and their various stages.
								</p>
								<img src="/opps.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>

								<p>
									Once on the Opportunities screen press the <strong>Add Opportunity</strong> button on the right and you can enter in the details of the Opportunity as shown below. 
								</p>

								<img src="/opps-add.png" className="mt-3 mb-5 doc-image img-fluid" alt=""/>
								<p>
									Make sure the <strong>Estimated Value</strong> field is a number so that this can be calculated and tracked in the Opportunies screen and hit Create to create your Opportunity.
								</p>


								<h2 id="opportunities-filtering">Filtering Opportunities</h2>
								<p>
									Once you have a number of opportunities in your pipeline you might want to start narrowing them down based on their <strong>Stage</strong>.
									To do this you just use the dropdown menu on the left of the Opportunities filter bar (which defaults to 'All Open') and select the stage you want to filter by.
								</p>
								<img src="/opps-filter-stage.png" className="mt-3 mb-5 doc-image img-fluid" alt="" width="300"/>
								<p>
									If your account has custom stages you may see different options here, but the concept is the same.
								</p>


								{/* 
								<div class="mt-5 mb-5 embed-responsive embed-responsive-16by9">
									<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/xxx?rel=0&amp;showinfo=0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
								</div>


								<h2>Creating your sales pipeline</h2>
								<p>
									Coming Soon.
								</p>
								*/}
{/* 
								<h2>Managing your Tasks</h2>
								<p>
									Coming Soon.
								</p>
*/}

								<h2 id="website">Integrating Reldesk into your website</h2>
								<p>
									If you need help with this, please contact us.
								</p>
{/*

								<h2>Next Steps</h2>
								<ul className="pt-3 pb-3">
									<li><a href="">Meggings seitan Wes</a></li>
									<li><a href="">Meggings seitan Wes</a></li>
									<li><a href="">Meggings seitan Wes</a></li>
									<li><a href="">Meggings seitan Wes</a></li>
								</ul>


								<p>
									Sriracha biodiesel taxidermy organic post-ironic, Intelligentsia salvia mustache 90's code editing brunch. Butcher polaroid VHS art party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag wolf squid tote bag. Tote bag cronut semiotics,
									raw denim deep v taxidermy messenger bag. Tofu YOLO Etsy, direct trade ethical Odd Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony, street art organic Bushwick artisan cliche semiotics ugh synth chillwave meditation.
									Shabby chic lomo plaid vinyl chambray Vice. Vice sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.
								</p>
 */}
							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>


	</Layout>
)

export default PageGettingStarted
